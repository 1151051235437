<template>
  <div>
    <div class="d-flex align-center justify-space-between mb-8">
      <div class="titulo-pagina">
        <div>
          {{ tituloFormulario }}
        </div>
        <div>
          <v-chip
            class="mr-1"
            small
          >
            {{ badges.instrumento }}
          </v-chip>
          <v-chip
            class="mx-1"
            small
          >
            {{ badges.certificado }}
          </v-chip>
        </div>
      </div>
      <input-select
        v-model="inputTabelaSelecionadaId"
        class="w-35"
        :label="labelSelect"
        :placeholder="$t('geral.inputs.selecione')"
        :options="opcoesTabela"
        em-filtro
        :clearable="false"
      />
    </div>
    <v-form
      ref="form"
      class="row"
    >
      <input-select
        :value="form.situacaoAvaliacao"
        disabled
        readonly
        class="col-12 col-md-3"
        :label="$t('modulos.certificados.avaliacao.situacao_avaliacao')"
        :placeholder="$t('modulos.certificados.avaliacao.situacao_avaliacao')"
        :options="opcoes.situacao_avaliacao_certificado"
        :icone="''"
      />
      <input-ocorrencia-instrumento
        v-model="form.ocorrencias"
        :label="$t('modulos.certificados.avaliacao.ocorrencia')"
        :placeholder="$t('modulos.certificados.avaliacao.ocorrencia')"
        :instrumento-id="form.instrumento?.value"
        class="col-12 col-md-7"
      />
      <input-radio
        v-model="form.flagInativarInstrumento"
        class="col-12 col-md-2"
        :label="$t('modulos.certificados.avaliacao.inativar_instrumento')"
        :options="opcoes.inativar_instrumentos"
        :divider="false"
        :disabled="inativarEhBloqueado"
      />
      <tabela-padrao
        v-model="tabela.selecionados"
        :dados="linhasPorTabela"
        class="mt-2 col-12"
        :colunas="tabela.colunas"
        :por-pagina="tabela.porPagina"
        :quantidade-paginas="tabela.quantidadePaginas"
        sem-paginacao
        sem-acoes
        :mostrar-checkbox="false"
      >
        <template v-slot:item.pontoCalibracao="{ item }">
          <span>
            {{ montarExibicaoPonto(item.item) }}
          </span>
        </template>
        <template v-slot:item.formulaValidacao="{ item }">
          <span>
            {{ buscarTextoFormulaValidacao(item.item.criterioAceitacao?.formulaValidacao) ?? ' - ' }}
          </span>
        </template>
        <template v-slot:item.situacaoAvaliacao="{ item }">
          <input-select
            v-model="item.item.situacaoAvaliacao"
            class="input-tabela mt-2"
            background-color="transparent"
            sem-label
            :multiplos="false"
            :options="buscarSituacaoLinhasEnum(item.item.situacaoAvaliacao)"
            :clearable="false"
            :disabled="item.item.situacaoAvaliacao == 'NaoValidado'"
            solo
            flat
            estilo-customizado="font-size: 0.85rem; font-weight: 500;"
          />
        </template>
      </tabela-padrao>
    </v-form>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao
        :disabled="!!linhasSemCriterio.length"
        :tooltip="toolTip"
        @click="justificarAvaliacao"
      >
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
      <botao-padrao
        class="ml-2"
        @click="revalidarCriterios"
      >
        {{ $t('modulos.certificados.avaliacao.revalidar_criterio') }}
      </botao-padrao>
    </div>
    <confirmacao-modal
      ref="confirmacao-modal"
      :form="form"
      @salvar-avaliacao="salvarAvaliacao"
    />
  </div>
</template>
<script>
import helpers from '@common/utils/helpers';
import { CertificadoAvaliacaoModel } from '@common/models/cadastros/CertificadoAvaliacaoModel';
import { EnumeradorService } from '@common/services/cadastros';
import { InputOcorrenciaInstrumento } from '@components/inputs';
import { CertificadoService } from '../../../../../common/services/cadastros/';
import ConfirmacaoModal from './modal/ConfirmacaoModal.vue';
import _ from 'lodash';
export default {
  components: { InputOcorrenciaInstrumento, ConfirmacaoModal },
  props: ['calibracaoId', 'retorno'],
  data: function () {
    return {
      form: new CertificadoAvaliacaoModel({}),
      situacaoLinhas: [],
      inputTabelaSelecionadaId: '',
      inputFaixaSelecionadaId: '',
      codigoCertificado: '',
      nomeInstrumento: '',
      opcoes: {
        inativar_instrumentos: helpers.BoleanoEnum,
        situacao_avaliacao_certificado: [],
        situacao_avaliacao: {
          opcoes: [
            {
              value: 'Aprovado',
              text: this.$t('enums.EnumSituacaoAvaliacao.Aprovado'),
              situacao: ['Aprovado', 'ReprovadoComRessalvas'],
            },
            {
              value: 'ReprovadoComRessalvas',
              text: this.$t(
                'enums.EnumSituacaoAvaliacao.ReprovadoComRessalvas'
              ),
              situacao: ['Aprovado', 'ReprovadoComRessalvas'],
            },
            {
              value: 'Reprovado',
              text: this.$t('enums.EnumSituacaoAvaliacao.Reprovado'),
              situacao: ['Reprovado', 'AprovadoComRessalvas'],
            },
            {
              value: 'AprovadoComRessalvas',
              text: this.$t('enums.EnumSituacaoAvaliacao.AprovadoComRessalvas'),
              situacao: ['Reprovado', 'AprovadoComRessalvas'],
            },
            {
              value: 'NaoValidado',
              text: this.$t('enums.EnumSituacaoAvaliacao.NaoValidado'),
              situacao: ['NaoValidado'],
            },
          ],
        },
        formulaValidacao: [],
      },
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'pontoCalibracao',
            text: this.$t(
              'modulos.certificados.avaliacao.tabela.ponto_calibracao'
            ),
            sortable: false,
          },
          {
            value: 'nome',
            text: this.$t('modulos.certificados.avaliacao.tabela.nome'),
            sortable: false,
            width: '120px',
          },
          {
            value: 'erro',
            text: this.$t('modulos.certificados.avaliacao.tabela.erro'),
            sortable: false,
            width: '120px',
            formatter: (v) => {
              if (v || v === 0) {
                return helpers.formatarNumeroComPrecisao(v);
              }
            },
          },
          {
            value: 'incerteza',
            text: this.$t('modulos.certificados.avaliacao.tabela.incerteza'),
            sortable: false,
            formatter: (v) => {
              if (v || v === 0) {
                return helpers.formatarNumeroComPrecisao(v);
              }
            },
          },
          {
            value: 'erroIncerteza',
            text: this.$t(
              'modulos.certificados.avaliacao.tabela.erro_incerteza'
            ),
            sortable: false,
            formatter: (v) => {
              if (v || v === 0) {
                return helpers.formatarNumeroComPrecisao(v);
              }
            },
          },
          {
            value: 'formula',
            text: this.$t('modulos.certificados.avaliacao.tabela.formula'),
            sortable: false,
          },
          {
            value: 'criterioValor',
            text: this.$t('modulos.certificados.avaliacao.tabela.criterio'),
            sortable: false,
            formatter: (a, b) => {
              switch (b.tipoReferencia) {
                case 'MaiorQue':
                  return `> ${helpers.formatarNumeroComPrecisao(a[0])}`;
                case 'MenorQue':
                  return `< ${helpers.formatarNumeroComPrecisao(a[0])}`;
                case 'EntreDoisValores':
                  return `${helpers.formatarNumeroComPrecisao(
                    a[0]
                  )} <  ${helpers.formatarNumeroComPrecisao(a[1])}`;
                default:
                  return a ? helpers.formatarNumeroComPrecisao(a[0]) : '';
              }
            },
          },
          {
            value: 'criterioAceitacao',
            text: this.$t('modulos.certificados.avaliacao.tabela.nome_criterio'),
            sortable: false,
            formatter: v => v ? v.nomeCriterioAceitacao : ''
          },
          {
            value: 'formulaValidacao',
            text: this.$t(
              'modulos.certificados.avaliacao.tabela.formula_validacao'
            ),
            sortable: false,
          },
          {
            value: 'situacaoAvaliacao',
            text: this.$t(
              'modulos.certificados.avaliacao.tabela.situacao_avaliacao'
            ),
            sortable: false,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 0,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
    };
  },
  computed: {
    labelSelect: function () {
      if (this.form.nivel == 'Terceiro')
        return this.$t('modulos.calibracao.formulario.abas.faixas');
      return this.$t('modulos.calibracao.formulario.abas.tabelas');
    },
    inativarEhBloqueado: function () {
      return !(
        this.form.situacaoAvaliacao == 'Reprovado' ||
        this.form.situacaoAvaliacao == 'ReprovadoComRessalvas'
      );
    },
    tituloFormulario: function () {
      return this.$t('modulos.certificados.avaliacao.titulo.texto');
    },
    badges: function () {
      return {
        instrumento: this.$t(
          'modulos.certificados.avaliacao.titulo.instrumento',
          { instrumento: this.nomeInstrumento }
        ),
        certificado: this.$t(
          'modulos.certificados.avaliacao.titulo.certificado',
          { certificado: this.codigoCertificado }
        ),
      };
    },
    opcoesTabela: function () {
      if (this.form.nivel == 'Terceiro') return this.form.faixas;
      return this.form.tabelas;
    },
    linhasPorTabela: function () {
      return _.orderBy(
        this.form.linhas.filter((el) => {
          if (this.form.nivel == 'Terceiro')
            return el.faixaId == this.inputTabelaSelecionadaId;
          return el.tabelaId == this.inputTabelaSelecionadaId;
        }),
        ['pontoCalibracao'],
        ['asc']
      );
    },
    toolTip: function () {
      if (this.linhasSemCriterio.length == 0) return '';

      let tooltip = this.linhasSemCriterio.reduce((acc, el) => {
        let id = el.faixaId || el.tabelaId;

        const tabelaExistentes = this.opcoesTabela.find((tabela) => {
          return tabela.value == id;
        });
        if (!tabelaExistentes) return '';
        if (acc.includes(tabelaExistentes.text)) return acc;
        acc.push(tabelaExistentes.text);
        return acc;
      }, []);
      if (tooltip.length >= 1) tooltip = tooltip.join(', ');
      return this.$t('modulos.certificados.avaliacao.tooltip_salvar', {
        tabelasSemCriterio: tooltip,
      });
    },
    linhasSemCriterio: function () {
      return this.form.linhas.filter((el) => {
        return !el.criterioValor;
      });
    },
    verificarSeAlterouAlgumaSituacao: function () {
      return _.isEqual(this.form.linhas, this.situacaoLinhas);
    },
  },
  async mounted() {
    await this.buscarFormulaValidacaoEnum();
    await this.buscarSituacaoCertificadoEnum();
    await this.buscar(this.calibracaoId);
  },
  methods: {
    buscarTextoFormulaValidacao(v){
      return this.opcoes.formulaValidacao.find((el) => el.value === v)?.text;
    },
    montarExibicaoPonto(item){
      if (item?.pontoCalibracao || item?.pontoCalibracao === 0) {
        return helpers.formatarNumeroComPrecisao(item.pontoCalibracao) + ' ' + item.unidadeMedida.simbolo;
      }
      return item.pontoCalibracao + ' ' + item.unidadeMedida.simbolo;
    },
    buscar: async function (id) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      await CertificadoService.buscarAvaliacao(id)
        .then((res) => {
          this.form = new CertificadoAvaliacaoModel(res.data);
          this.tabela.dados = this.form.linhas;
          this.situacaoLinhas = _.cloneDeep(this.form.linhas);
          this.codigoCertificado = res.data.certificado.nome;
          this.nomeInstrumento = res.data.instrumento.nome;

          let tabelas = !this.form.nivel == 'Terceiro'
            ? this.form.tabelas
            : this.form.faixas;
          this.inputTabelaSelecionadaId = null;
          if (tabelas.length > 0)
            this.inputTabelaSelecionadaId = tabelas[0].value;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    buscarSituacaoLinhasEnum: function (situacaoEnum) {
      if (!situacaoEnum) return [];
      return this.opcoes.situacao_avaliacao.opcoes.filter((el) => {
        return el.situacao.includes(situacaoEnum);
      });
    },
    buscarSituacaoCertificadoEnum: async function () {
      await EnumeradorService.buscar('EnumSituacaoAvaliacao').then((res) => {
        this.opcoes.situacao_avaliacao_certificado = res;
      });
    },
    buscarFormulaValidacaoEnum: async function () {
      await EnumeradorService.buscar(
        'EnumFormulaValidacaoCriterioValidacao'
      ).then((res) => {
        this.opcoes.formulaValidacao = res;
      });
    },
    cancelar: function () {
      this.$router.push({
        name: this.retorno || this.$route.query?.retorno || 'certificado',
      });
    },

    justificarAvaliacao: function () {
      if (!this.verificarSeAlterouAlgumaSituacao) {
        this.$refs['confirmacao-modal'].abrirModal();
        return;
      }
      this.salvarAvaliacao();
    },
    salvarAvaliacao: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      CertificadoService.avaliarCertificado(
        this.calibracaoId,
        this.form.request
      )
        .then(() => {
          this.toastSucesso(this.$t(`modulos.certificados.avaliacao.sucesso`));
          this.$router.push({
            name: this.retorno || this.$route.query?.retorno || 'certificado',
          });
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },

    revalidarCriterios: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      CertificadoService.revalidarCertificado(
        this.calibracaoId,
        this.form.request
      )
        .then(() => {
          this.toastSucesso(
            this.$t(`modulos.certificados.avaliacao.revalidar_sucesso`)
          );
        })
        .then(() => {
          this.buscar(this.calibracaoId);
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>

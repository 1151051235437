import { DropdownModel } from '../geral/DropdownModel';
import _ from 'lodash';
export class CertificadoAvaliacaoModel {
  constructor({
    certificado,
    instrumento,
    situacaoAvaliacao,
    ocorrencias,
    linhas = [],
    flagInativarInstrumento = false,
    justificativa = '',
    flagTerceiro = false,
    faixas = [],
    tabelas = [],
    nivel
  }) {
    if (certificado) this.certificado = new DropdownModel(certificado);
    this.instrumento = instrumento
    if (instrumento) this.instrumento = new DropdownModel(instrumento);
    this.situacaoAvaliacao = situacaoAvaliacao;
    this.ocorrencias = ocorrencias;
    if (ocorrencias) this.ocorrencias = new DropdownModel(ocorrencias);
    this.linhas = linhas;
    this.flagInativarInstrumento = flagInativarInstrumento;
    this.justificativa = justificativa;
    this.flagTerceiro = flagTerceiro;
    this.nivel = nivel;
    if (faixas)
      this.faixas = new DropdownModel(faixas)
    this.tabelas = tabelas.map((el) => {
      return {
        value: el.tabela.id,
        text: `${el.procedimentoEletronico.nome} - ${el.tabela.nome}${el.unidadeMedida
          ? ` - ${el.amplitudeMinima} a ${el.amplitudeMaxima} ${el.unidadeMedida.nome}`
          : ""
          }`,
      };
    });
  }
  get request() {
    let retorno = _.cloneDeep(this);

    retorno.ocorrenciasIds = retorno.ocorrencias?.map((o) => o.value);
    retorno.linhas = retorno.linhas?.map((l) => {
      return { linhaId: l.linhaId, situacaoAvaliacao: l.situacaoAvaliacao };
    });

    return retorno;
  }
}

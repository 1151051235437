<template>
  <modal-padrao
    ref="modal-avaliacao"
    :titulo="$t('modulos.certificados.avaliacao.confirmar_avaliacao')"
    :titulo-ok="$t('geral.botoes.confirmar')"
    :titulo-cancelar="$t('geral.botoes.cancelar')"
    @ok="salvarAvaliacao"
  >
    <v-form
      ref="form"
      class="row"
    >
      <span class="col-12 mt-2">
        {{ $t('modulos.certificados.avaliacao.confirmar_avaliacao_informativo') }}
      </span>
      <input-textarea
        v-model="form.justificativa"
        class="col-12"
        :label="$t('modulos.certificados.avaliacao.confirmar_avaliacao_justificativa')"
        obrigatorio
        :max="5000"
      />
    </v-form>
    <v-divider />
  </modal-padrao>
</template>
<script>
export default {
  props:{
    form:{}
  },
  methods: {
    abrirModal() {
      this.$refs['modal-avaliacao'].abrirModal()
    },
    salvarAvaliacao() {
      if(!this.$refs.form.validate()) return
          this.$emit('salvar-avaliacao')
          this.$refs['modal-avaliacao'].fecharModal()
    },
  },
}
</script>
